.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
    text-align: center;
    text-transform: capitalize;
}