.parameters {

}

.topN {
    color: white;
}

.topN input {
    width: 50px;
}