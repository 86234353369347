.container {
  display: flex;
  justify-content: center;
  padding: 2rem;
  /* height: 100%; */
  row-gap: 2rem;
  column-gap: 2rem;
  /* should wrap the content  */
  flex-wrap: wrap;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #37abc8;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  outline: none;
  transition: all 0.2s;
}

.button_title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: white;
  margin-top: 1rem;
}

.button:hover {
  background-color: #88cede;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.image {
  width: 250px;
  height: 200px;
  margin-bottom: 0.5rem;
}

.link {
  text-decoration: none;
  color: white;
}
