.parameters {

}

.topN {
    color: white;
}

.topN input {
    width: 50px;
}

.dateInput {
    width: 110px !important;
}